import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { BsFillMoonFill, BsSunFill } from 'react-icons/bs'
import { GiEasterEgg } from 'react-icons/gi'
import { useEffect, useState } from 'react'
import { setSettings } from '../redux/settings'
import { activateSeth } from '../helpers'
import { FaArrowAltCircleRight, FaGraduationCap } from 'react-icons/fa'
import { MdDoubleArrow } from 'react-icons/md'

export default function Sidebar({ access, submenu }) {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const DEFAULT_LIST = [
        // 'product-setup',
        // 'cost-adjuster',
        'processing-times',
        'procurement',
        'marketing',
        'tax-exemptions',
        // 'analytics',
        'price-cuts',
        'EDI',
        'sales',
        'users',
        'admin',
        'store-manager',
    ]
    const currentList = access.includes('*') ? DEFAULT_LIST : access
    const user = useSelector((state) => state.user)
    const settings = useSelector((state) => state.settings)
    const [needsRefresh, setNeedsRefresh] = useState(false)
    let submenuTitles = []
    if (submenu) {
        submenuTitles = submenu.map((menuItem) => menuItem.label)
    }
    if (submenu && submenuTitles.includes('migrations') && !user.roles.includes('developer')) {
        submenu = submenu.filter((menuItem) => menuItem.label !== 'migrations')
    }
    if (submenu && submenuTitles.includes('Inventory Emails') && ![1, 4, 12, 26, 10, 20, 34].includes(user.id)) {
        submenu = submenu.filter((menuItem) => menuItem.label !== 'Inventory Emails')
    }
    if (submenu && submenuTitles.includes('score-settings') && !(user.type === 'Admin' || user.type === 'SuperAdmin')) {
        submenu = submenu.filter((menuItem) => menuItem.label !== 'score-settings')
    }
    if (submenu && submenuTitles.includes('analytics') && !(user.type === 'Admin' || user.type === 'SuperAdmin')) {
        submenu = submenu.filter((menuItem) => menuItem.label !== 'analytics')
    }
    if (submenu && submenuTitles.includes('table-sync') && !window.location.origin.includes('localhost')) {
        submenu = submenu.filter((menuItem) => menuItem.label !== 'table-sync')
    }
    if (user.type === 'SuperAdmin') {
        currentList.splice(4, 0, 'analytics')
    }
    useEffect(() => {
        if (needsRefresh) {
            setNeedsRefresh(false)
            localStorage.setItem('theme', settings.theme)
            if (settings.theme !== 'dark' && document.querySelector('html').classList.contains('dark')) {
                document.querySelector('html').classList.remove('dark')
            } else {
                document.querySelector('html').classList.add('dark')
            }
        }
    }, [needsRefresh])
    useEffect(() => {
        document.documentElement.style.setProperty(`--left-width`, settings.hidebar ? '16px' : '216px')
    }, [settings])
    const handleDarkChange = () => {
        dispatch(
            setSettings({
                theme: settings.theme === 'dark' ? '' : 'dark',
            })
        )
        setNeedsRefresh(true)
    }
    return (
        <div
            className={`fixed w-leftWidth top-[50px] z-[30] ${
                settings.hidebar ? 'left-[-200px]' : 'left-0'
            } py-[16px] pl-[16px] pr-[16px] bg-bg1 text-text1 dark:text-darktext1 dark:bg-darkbg1 h-[calc(100vh-50px)] flex flex-col`}
        >
            <button
                className='absolute top-[16px] right-[-10px] p-[2px] rounded-full border-2 border-accent1 dark:border-darkaccent1 bg-bg1 dark:bg-darkbg1 text-accent1 dark:text-darkaccent1'
                onClick={() => dispatch(setSettings({ hidebar: !settings.hidebar }))}
            >
                <MdDoubleArrow
                    className={`w-[16px] h-[16px] transition-all duration-300 ${settings.hidebar ? '' : 'rotate-180'}`}
                />
            </button>
            <div className='overflow-auto capitalize font-semibold text-sm mb-[16px]'>
                <div
                    className={`mb-[4px]  w-full px-2 rounded-md cursor-pointer ${
                        location.pathname === '/home' ? 'font-bold text-[17px] bg-lightgrey dark:bg-darkbg2' : ''
                    }`}
                    onClick={() => navigate(`/home`)}
                >
                    Home
                </div>
                <div>
                    <div
                        className={`mb-[4px]  w-full px-2 rounded-md cursor-pointer ${
                            window.location.href.includes(`${window.location.host}/orders`)
                                ? 'font-bold text-[17px] bg-lightgrey dark:bg-darkbg2'
                                : ''
                        }`}
                        onClick={() => navigate(`/orders`)}
                    >
                        Orders
                    </div>
                    {window.location.href.includes(`${window.location.host}/orders`) &&
                        submenu &&
                        submenu.map((subitem) => (
                            <div
                                key={subitem.label}
                                className={`ml-2 mb-[4px] text-[14px] max-w-[90%] w-full px-2 rounded-md cursor-pointer ${
                                    window.location.href.includes(`${window.location.host}/orders/${subitem.label}`)
                                        ? 'font-bold bg-lightgrey dark:bg-darkbg2'
                                        : ''
                                }`}
                                onClick={() => navigate(`/orders/${subitem.address}`)}
                            >
                                {subitem.label.replaceAll('-', ' ')}
                            </div>
                        ))}
                </div>

                <div>
                    <div
                        className={`mb-[4px]  w-full cursor-pointer px-2 rounded-md ${
                            window.location.href.includes(`${window.location.host}/draftOrders`)
                                ? 'font-bold text-[17px] bg-lightgrey dark:bg-darkbg2'
                                : ''
                        }`}
                        onClick={() => navigate(`/draftOrders`)}
                    >
                        Draft Orders
                    </div>
                </div>
                <div
                    className={`mb-[4px]  w-full px-2 rounded-md cursor-pointer ${
                        location.pathname === '/top-movers' ? 'font-bold text-[17px] bg-lightgrey dark:bg-darkbg2' : ''
                    }`}
                    onClick={() => navigate(`/top-movers`)}
                >
                    Top Movers
                </div>
                <div>
                    <div
                        className={`mb-[4px]  w-full px-2 rounded-md cursor-pointer ${
                            window.location.href.includes(`${window.location.host}/scrape`)
                                ? 'font-bold text-[17px] bg-lightgrey dark:bg-darkbg2'
                                : ''
                        }`}
                        onClick={() => navigate(`/scrape`)}
                    >
                        Price Scrape
                    </div>
                    {window.location.href.includes(`${window.location.host}/scrape`) &&
                        submenu &&
                        submenu.map((subitem) => (
                            <div
                                key={subitem.label}
                                className={`ml-2 mb-[4px] text-[14px] max-w-[90%] w-full px-2 rounded-md cursor-pointer ${
                                    window.location.href.includes(`${window.location.host}/scrape/${subitem.label}`)
                                        ? 'font-bold bg-lightgrey dark:bg-darkbg2'
                                        : ''
                                }`}
                                onClick={() => navigate(`/scrape/${subitem.address}`)}
                            >
                                {subitem.label.replaceAll('-', ' ')}
                            </div>
                        ))}
                </div>
                <div>
                    <div
                        className={`mb-[4px]  w-full px-2 rounded-md cursor-pointer ${
                            window.location.href.includes(`${window.location.host}/tasks`)
                                ? 'font-bold text-[17px] bg-lightgrey dark:bg-darkbg2'
                                : ''
                        }`}
                        onClick={() => navigate(`/tasks`)}
                    >
                        Tasks
                    </div>
                    {window.location.href.includes(`${window.location.host}/tasks`) &&
                        submenu &&
                        submenu.map((subitem) => (
                            <div
                                key={subitem.label}
                                className={`ml-2 mb-[4px] text-[14px] max-w-[90%] w-full px-2 rounded-md cursor-pointer ${
                                    window.location.href.includes(`${window.location.host}/tasks/${subitem.label}`)
                                        ? 'font-bold bg-lightgrey dark:bg-darkbg2'
                                        : ''
                                }`}
                                onClick={() => navigate(`/tasks/${subitem.address}`)}
                            >
                                {subitem.label.replaceAll('-', ' ')}
                            </div>
                        ))}
                </div>
                <div
                    className={`mb-[4px]  w-full px-2 rounded-md cursor-pointer ${
                        location.pathname === '/customers' ? 'font-bold text-[17px] bg-lightgrey dark:bg-darkbg2' : ''
                    }`}
                    onClick={() => navigate(`/customers`)}
                >
                    Customers
                </div>

                <div
                    className={`mb-[4px]  w-full px-2 rounded-md cursor-pointer ${
                        location.pathname === '/vendorsInfo' ? 'font-bold text-[17px] bg-lightgrey dark:bg-darkbg2' : ''
                    }`}
                    onClick={() => navigate(`/vendorsInfo`)}
                >
                    Vendors
                </div>
                <div
                    className={`mb-[4px]  w-full px-2 rounded-md cursor-pointer ${
                        location.pathname === '/returns' ? 'font-bold text-[17px] bg-lightgrey dark:bg-darkbg2' : ''
                    }`}
                    onClick={() => navigate(`/returns`)}
                >
                    Returns
                </div>
                <div>
                    <div
                        className={`mb-[4px]  w-full px-2 rounded-md cursor-pointer ${
                            window.location.href.includes(`${window.location.host}/products`)
                                ? 'font-bold text-[17px] bg-lightgrey dark:bg-darkbg2'
                                : ''
                        }`}
                        onClick={() => navigate(`/products`)}
                    >
                        Products
                    </div>
                    {window.location.href.includes(`${window.location.host}/products`) &&
                        submenu &&
                        submenu.map((subitem) => (
                            <div
                                key={subitem.label}
                                className={`ml-2 mb-[4px] text-[14px] max-w-[90%] w-full px-2 rounded-md cursor-pointer ${
                                    window.location.href.includes(`${window.location.host}/products/${subitem.address}`)
                                        ? 'font-bold bg-lightgrey dark:bg-darkbg2'
                                        : ''
                                }`}
                                onClick={() => navigate(`/products/${subitem.address}`)}
                            >
                                {subitem.label.replaceAll('-', ' ')}
                            </div>
                        ))}
                </div>
                {currentList.map((item) => {
                    return (
                        <div key={item}>
                            <div
                                className={`mb-[4px]  w-full px-2 rounded-md cursor-pointer ${
                                    window.location.href.includes(`${window.location.host}/${item}`)
                                        ? 'font-bold text-[17px] bg-lightgrey dark:bg-darkbg2'
                                        : ''
                                }`}
                                onClick={() => navigate(`/${item}`)}
                            >
                                {item.replaceAll('-', ' ')}
                            </div>
                            {window.location.href.includes(`${window.location.host}/${item}`) &&
                                submenu &&
                                submenu.map((subitem) => {
                                    return (
                                        <div
                                            key={subitem.label}
                                            className={`ml-2 mb-[4px] text-[14px] max-w-[90%] w-full px-2 rounded-md cursor-pointer ${
                                                window.location.href.includes(
                                                    `${window.location.host}/${item}/${subitem.address}`
                                                )
                                                    ? 'font-bold bg-lightgrey dark:bg-darkbg2'
                                                    : ''
                                            }`}
                                            onClick={
                                                subitem.label === 'keys'
                                                    ? () =>
                                                          window.open(
                                                              'https://docs.google.com/document/d/1O3I5qMe9w8zRSlM7FO7rCPWDtWXsInGOwKSccP7DtxA/edit?usp=sharing',
                                                              '_blank'
                                                          )
                                                    : () => navigate(`/${item}/${subitem.address}`)
                                            }
                                        >
                                            {subitem.label.replaceAll('-', ' ')}
                                        </div>
                                    )
                                })}
                        </div>
                    )
                })}
                <div>
                    <div
                        className={`mb-[4px]  w-full px-2 rounded-md cursor-pointer ${
                            window.location.href.includes('/development')
                                ? 'font-bold text-[17px] bg-lightgrey dark:bg-darkbg2'
                                : ''
                        }`}
                        onClick={() => navigate(`/development`)}
                    >
                        Development
                    </div>

                    {window.location.href.includes(`${window.location.host}/development`) &&
                        submenu &&
                        submenu.map((subitem) => (
                            <div
                                key={subitem.label}
                                className={`ml-2 mb-[4px] text-[14px] max-w-[90%] w-full px-2 rounded-md cursor-pointer ${
                                    window.location.href.includes(
                                        `${window.location.host}/development/${subitem.address}`
                                    )
                                        ? 'font-bold bg-lightgrey dark:bg-darkbg2'
                                        : ''
                                }`}
                                onClick={() => navigate(`/development/${subitem.address}`)}
                            >
                                {subitem.label.replaceAll('-', ' ')}
                            </div>
                        ))}
                </div>
                {/* {(user.id === 4 || user.id === 12) && user.type === 'SuperAdmin' && (
                    <div
                        className={`mb-[4px]  w-full px-2 rounded-md cursor-pointer ${
                            location.pathname === '/vendors-map'
                                ? 'font-bold text-[17px] bg-lightgrey dark:bg-darkbg2'
                                : ''
                        }`}
                        onClick={() => navigate(`/vendors-map`)}
                    >
                        Vendors COMPANY MAPPING
                    </div>
                )} */}
            </div>
            <div className='flex gap-[8px] mt-auto items-center'>
                <div className='cursor-pointer' onClick={handleDarkChange}>
                    {settings.theme === 'dark' ? (
                        <BsSunFill size={'25px'} className='fill-darkaccent1' />
                    ) : (
                        <BsFillMoonFill size={'25px'} className='fill-accent1' />
                    )}
                </div>
                <button
                    onClick={() =>
                        setSearchParams((prev) => {
                            prev.set('knowledgeScreen', 'Open')
                            return prev
                        })
                    }
                >
                    <FaGraduationCap className='w-8 h-8 text-blue dark:text-accent' />
                </button>
                <div
                    id='easter-egg'
                    className='cursor-pointer opacity-0 hover:opacity-100 transition-all'
                    onClick={() => activateSeth()}
                >
                    {settings.theme === 'dark' ? (
                        <GiEasterEgg size={'30px'} className='fill-darkaccent1' />
                    ) : (
                        <GiEasterEgg size={'30px'} className='fill-accent1' />
                    )}
                </div>
            </div>
        </div>
    )
}
