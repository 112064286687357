import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import pdfMake from 'pdfmake/build/pdfmake.js'
import pdfFonts from 'pdfmake/build/vfs_fonts.js'
import { LoadingGear, sendToast, vFetch } from '../helpers/index'
import PurchaseOrder from './components/PurchaseOrder'
import { parseIfJson } from './helpers'
import { PurchaseOrderLineItem, PurchaseOrderType, SupplierType } from './PurchaseOrder.types'
pdfMake.vfs = pdfFonts.pdfMake.vfs

export default function EditPurchaseOrdersScreen() {
    const { po_id } = useParams()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [orders, setOrders] = useState<PurchaseOrderType[]>([])
    const [suppliers, setSuppliers] = useState<SupplierType[]>([])
    const [products, setProducts] = useState<PurchaseOrderLineItem[]>([])

    async function initializeScreen() {
        const [suppliers, products, orders] = await Promise.all([
            getSuppliers(),
            getFormattedProducts(),
            getOrders(),
        ]).catch((err) => {
            console.error(err)
            return [[], [], []]
        })
        setSuppliers(suppliers)
        setProducts(products)
        setOrders(formatOrders(orders as PurchaseOrderType[]))
        setLoading(false)
    }
    async function getOrders() {
        const orders = await vFetch(`/v1/orders/purchase-orders/${po_id}`).then((res) => [
            parseIfJson(res.purchaseOrder),
        ])
        return orders
    }
    function formatOrders(currentOrders: PurchaseOrderType[]) {
        return currentOrders.map((order) => ({ ...order, vendor: order.line_items[0].vendor }))
    }
    async function getSuppliers() {
        const suppliers = await vFetch(`/v1/suppliers/all`)
            .then((res) => res.suppliers.map(parseIfJson))
            .catch(console.error)
        return suppliers || []
    }
    async function getFormattedProducts() {
        const [products, variants] = await Promise.all([
            vFetch(`/v2/products?fields=vendor,id,title&limit=999999`).then((res) => res.products),
            vFetch(
                `/v2/variants?fields=id,product_id,position,sku,unit_cost,custom_supplier_sku,custom_supplier_model_number,custom_upc&limit=999999`
            ).then((res) => res.variants),
        ])
        const newProducts = []
        const productMap: {
            [key: string]: any
        } = {}
        for (let product of products) {
            productMap[product.id] = product
        }
        for (let variant of variants) {
            if (productMap[variant.product_id]) {
                newProducts.push({
                    ...productMap[variant.product_id],
                    variant_id: variant.id,
                    product_id: variant.product_id,
                    item_cost: variant.unit_cost,
                    title: `${variant.sku} - ${productMap[variant.product_id].title}`,
                    product_title: productMap[variant.product_id].title,
                    model_number: variant.custom_supplier_model_number || undefined,
                    upc: variant.custom_upc || undefined,
                    supplier_sku: variant.custom_supplier_sku || undefined,
                })
            }
        }
        return newProducts.sort((a, b) => (a.title > b.title ? 1 : -1))
    }

    const handleReSend = () => {
        const unsuppliedPOs: string[] = []
        const [purchaseOrder] = orders
        const [orderName, version] = purchaseOrder.order_name.split('-')
        const newVersion = parseInt(version || '0') + 1
        purchaseOrder.order_name = `${orderName}-${newVersion}`
        if (purchaseOrder.supplier && purchaseOrder.accepts_email) {
            vFetch(`/v1/orders/purchase-orders`, {
                method: 'PUT',
                body: JSON.stringify({
                    ...purchaseOrder,
                }),
                cb: (res: any) => {
                    navigate('/orders/purchase-orders')
                },
            })
        } else {
            orders.forEach((o) => {
                if (!o.supplier) unsuppliedPOs.push(o.order_name)
            })
            sendToast(
                'Please add a supplier to order' + (unsuppliedPOs.length > 1 ? 's: ' : ': ') + unsuppliedPOs.join(', ')
            )
        }
    }
    const handleResendEDI = (ediOrderType: string) => {
        const [purchaseOrder] = orders
        if (ediOrderType === '00') {
            const [orderName, version] = purchaseOrder.order_name.split('-')
            const newVersion = parseInt(version || '0') + 1
            purchaseOrder.order_name = `${orderName}-${newVersion}`
        }
        vFetch(`/v1/orders/purchase-orders/edi`, {
            method: 'PUT',
            body: JSON.stringify({
                ...purchaseOrder,
                ediOrderType,
                total: purchaseOrder.line_items
                    .map((item) => item.quantity * (item.item_cost || 0))
                    .reduce((acc, cur) => acc + cur, 0),
            }),
            cb: (res: any) => {
                navigate('/orders/purchase-orders')
            },
        })
    }

    useEffect(() => {
        initializeScreen()
    }, [])

    return (
        <div className=''>
            {loading ? (
                <div className='grid fixed top-[50px] left-[216px] w-[calc(100%-216px)] h-[100%] justify-center items-center bg-[rgba(0,0,0,0.2)] z-50'>
                    <LoadingGear />
                </div>
            ) : (
                <>
                    <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Purchase Order</h1>
                    {po_id &&
                        orders &&
                        orders[0] &&
                        (!orders[0].supplier_edi_info ? (
                            <button
                                onClick={handleReSend}
                                className='fixed top-[50px] right-0 py-[4px] px-[16px] font-bold bg-blue text-white dark:text-darkness dark:bg-accent border-0 z-[5] uppercase'
                            >
                                Resend Purchase Order
                            </button>
                        ) : (
                            <div className='fixed top-[50px] z-[5] right-0 flex gap-[8px]'>
                                <button
                                    onClick={() => handleResendEDI('05')}
                                    className='py-[4px] px-[8px] text-[14px] font-bold bg-blue text-white dark:text-darkness dark:bg-accent border-0 rounded-b-[4px] z-[5] uppercase'
                                >
                                    Send Change Order
                                </button>
                                <button
                                    onClick={() => handleResendEDI('00')}
                                    className='py-[4px] px-[8px] text-[14px] font-bold bg-fire text-white dark:text-darkness border-0 z-[5] rounded-b-[4px] uppercase'
                                >
                                    Send As New Order
                                </button>
                                <button
                                    onClick={() => handleResendEDI('01')}
                                    className='py-[4px] px-[8px] text-[14px] font-bold bg-red text-white dark:text-offwhite border-0 z-[5] rounded-b-[4px] uppercase'
                                >
                                    Send Cancellation Order
                                </button>
                            </div>
                        ))}
                    {suppliers.length > 0 && orders.length > 0 && products.length > 0 && (
                        <PurchaseOrder
                            key={(orders[0] || {}).id || '1'}
                            order={orders[0]}
                            orderIndex={0}
                            orders={orders}
                            setOrders={setOrders}
                            suppliers={suppliers}
                            products={products}
                            po_id={po_id}
                        />
                    )}
                </>
            )}
        </div>
    )
}
