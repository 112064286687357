import { ExternalLinkIcon } from '@radix-ui/react-icons'
import { InventoryEmailType } from 'edi/EDIScreens.types'
import { cn } from 'helpers'
import { Link } from 'react-router-dom'

export default function FailedItemsList({ email }: { email: InventoryEmailType }) {
    const identifier = email?.update_failures[0]?.identifier
    return (
        <div>
            <div className='w-full relative p-[16px] pt-[20px] rounded-[4px] border border-blue/25 dark:border-darkgrey dark:bg-darkness shadow-md'>
                <p className='absolute top-0 left-0 bg-danger dark:bg-darkdanger rounded-tl-[4px] text-white dark:text-white leading-[1] text-[10px] uppercase font-bold p-[2px] px-1'>
                    Product Update Failures
                </p>
                <div className='flex flex-col gap-1 rounded-[4px] dark:text-offwhite'>
                    <div className='flex flex-col'></div>
                </div>
                <div>
                    <div className='grid grid-cols-[220px_220px_220px_120px_200px_120px_120px] border-b'>
                        <p>{identifier}</p>
                        <p>SKU</p>
                        <p>Variant Id</p>
                        <p>Quantity</p>
                        <p>Ignore Auto Updates</p>
                        <p>Cost Update</p>
                        <p>New Cost</p>
                    </div>
                    {email?.update_failures?.map((item: any) => {
                        const variantURL = `https://admin.shopify.com/store/factorypure/products/${item?.product_id}/variants/${item?.variant_id}`
                        return (
                            <div className='grid grid-cols-[220px_220px_220px_120px_200px_120px_120px]'>
                                <p>{item[identifier]}</p>
                                <p>{item?.sku ?? 'No product found'}</p>
                                {item?.variant_id ? (
                                    <Link
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        to={variantURL}
                                        draggable={false}
                                        className='underline flex gap-2 items-center'
                                    >
                                        {item?.variant_id ?? 'No product found'}
                                        <ExternalLinkIcon />
                                    </Link>
                                ) : (
                                    <p>No product found</p>
                                )}
                                <p>{item?.quantity}</p>
                                <p>
                                    {item?.custom_ignore_auto_updates
                                        ? item.custom_ignore_auto_updates === 1
                                            ? 'TRUE'
                                            : 'FALSE'
                                        : '-'}
                                </p>
                                <p className={cn(item?.cost_success === 'FAILED' && 'text-danger')}>
                                    {item?.cost_success}
                                </p>
                                <p>{typeof item?.cost === 'string' ? item?.cost.trim() || '(NO VALUE)' : '-'}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
