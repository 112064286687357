import { useEffect, useState } from 'react'
import Modal from './Modal'
import { vFetch } from '../helpers'

export default function Suppliers() {
    const placeholderSupplier = {
        name: '',
        accepts_email: 1,
        accepts_edi: 0,
        auto_email_address: '[]',
        vendor_info_sheet_email: '[]',
        contact_name: '',
        notes: '',
        unfulfilled_orders: '[]',
        unfulfilled_count: 0,
        vendor_names: '[]',
        country: 'United States',
        currency: 'USD',
        show_cost: 1,
        edi_info: '{}',
        test_mode: 0,
    }
    const placeholderArr = []
    for (let i = 0; i < 20; i++) {
        placeholderArr.push({ ...placeholderSupplier })
    }
    const [suppliers, setSuppliers] = useState(placeholderArr)
    const [vendors, setVendors] = useState([])
    const [filteredSuppliers, setFilteredSuppliers] = useState([])
    const [selectedSupplier, setSelectedSupplier] = useState(null)
    const [needsRefresh, setNeedsRefresh] = useState(true)
    const [search, setSearch] = useState('')
    const defaultSupplier = {
        name: '',
        auto_email_address: [],
        vendor_info_sheet_email: [],
        contact_name: '',
        notes: '',
        vendor_names: [],
        country: 'United States',
        currency: 'USD',
        show_cost: true,
        accepts_email: true,
        accepts_edi: false,
        edi_info: {},
        test_mode: false,
    }
    useEffect(() => {
        if (needsRefresh) {
            setNeedsRefresh(false)
            setSearch('')
            vFetch(`/v1/suppliers/all`, {
                cb: (res) => {
                    if (res.success) {
                        setSuppliers(res.suppliers.map((s) => ({ ...s, edi_info: JSON.parse(s.edi_info) || {} })))
                        setFilteredSuppliers(
                            res.suppliers.map((s) => ({ ...s, edi_info: JSON.parse(s.edi_info) || {} }))
                        )
                    }
                },
            })
            vFetch(`/v2/products/vendors`, {
                cb: (res) => {
                    if (res.success) {
                        setVendors(res.vendors)
                    }
                },
            })
        }
    }, [needsRefresh])
    useEffect(() => {
        const filterFunc = (item) => {
            if (item.name.toLowerCase().includes(search.toLowerCase())) {
                return true
            }
            if (item.vendor_names && item.vendor_names.toLowerCase().includes(search.toLowerCase())) {
                return true
            }
            return false
        }
        setFilteredSuppliers([...suppliers].filter(filterFunc))
    }, [search])
    return (
        <>
            <div className='flex items-center mb-[8px] gap-[8px]'>
                <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Suppliers</h1>
                <input
                    onChange={({ target }) => setSearch(target.value)}
                    value={search}
                    class='py-[2px] px-[8px] dark:py-[4px] dark:border-none border border-grey bg-faintplus rounded-[4px] dark:text-white focus:outline-none'
                    type='text'
                    placeholder='Search'
                />
                <button
                    className='ml-auto py-[4px] px-[16px] bg-blue text-white dark:bg-accent dark:text-darkness rounded-[4px] shadow-small font-bold uppercase font-bai'
                    onClick={() => setSelectedSupplier(defaultSupplier)}
                >
                    + New Supplier
                </button>
            </div>
            <div className='dark:bg-darkaccent rounded-[4px] shadow-small p-[32px]'>
                <div className='bg-white grid grid-cols-[2fr_2fr_1fr_1fr_1fr_1fr] sticky top-[50px] dark:bg-darkaccent p-[16px] mt-[-16px] gap-[8px]'>
                    <p className='dark:text-offwhite font-[600] text-[12px] uppercase font-bai relative left-[-16px]'>
                        Company
                    </p>
                    <p className='dark:text-offwhite font-[600] text-[12px] uppercase font-bai'>Brands</p>
                    <p className='dark:text-offwhite font-[600] text-[12px] uppercase font-bai'>Emails</p>
                    <p className='dark:text-offwhite font-[600] text-[12px] uppercase font-bai'>Country</p>
                    <p className='dark:text-offwhite font-[600] text-[12px] uppercase font-bai'>Currency</p>
                    <p className='dark:text-offwhite font-[600] text-[12px] uppercase font-bai'>Active Orders</p>
                </div>
                {filteredSuppliers.map((s) => (
                    <div
                        onClick={() => setSelectedSupplier(s)}
                        className='w-full p-[16px] bg-lightgrey dark:bg-darkness my-[4px] rounded-[4px] grid grid-cols-[2fr_2fr_1fr_1fr_1fr_1fr] gap-[8px]'
                    >
                        <p className='dark:text-offwhite'>{s.name}</p>
                        <p className='dark:text-offwhite'>{s.vendor_names && JSON.parse(s.vendor_names).join(', ')}</p>
                        <p className='dark:text-offwhite'>{JSON.parse(s.auto_email_address).length}</p>
                        <p className='dark:text-offwhite'>{s.country}</p>
                        <p className='dark:text-offwhite'>{s.currency}</p>
                        <p className='dark:text-offwhite'>{JSON.parse(s.unfulfilled_orders).length}</p>
                    </div>
                ))}
                <Modal
                    vendors={vendors}
                    supplier={selectedSupplier}
                    setSupplier={setSelectedSupplier}
                    setNeedsRefresh={setNeedsRefresh}
                />
            </div>
        </>
    )
}
