import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'

export const taxExemptions = createQueryKeys('taxExemptions', {
    list: (filters: any, search?: string) => ({
        queryKey: [filters, search],
        queryFn: () => service.getTaxExemptions(filters, search),
    }),
    detail: (taxExemptionId: any) => ({
        queryKey: [taxExemptionId],
        queryFn: () => service.getTaxExemptionDetail(taxExemptionId),
    }),
    forms: (taxExemptionId: any) => ({
        queryKey: [taxExemptionId],
        queryFn: () => service.getTaxExemptionForms(taxExemptionId),
    }),
})

export const taxExemptionQueries = mergeQueryKeys(taxExemptions)
