import { Button } from 'custom_components/component_Basics/Button'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { cn, sendToast } from 'helpers'
import Input from 'procurement/components/Input'
import Select from 'procurement/components/Select'
import { useEffect, useRef, useState } from 'react'
import { FaExternalLinkAlt, FaTimes, FaTrash } from 'react-icons/fa'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useDeleteTaxExemption, useGetTaxExemptionDetail, useUpdateTaxExemption } from 'taxExemptions/api/useQueries'
import TaxExemptionSelector from 'taxExemptions/components/TaxExemptionSelector'
import TaxFormList from 'taxExemptions/lists/TaxFormList'

export default function EditTaxExemptionModal({ taxExemptionId }: { taxExemptionId: string }) {
    const [searchParams, setSearchParams] = useSearchParams()
    const modalRef = useRef<HTMLDivElement>(null)
    const [editedFields, setEditedFields] = useState<any>({})
    const [showDelete, setShowDelete] = useState(false)
    const edited = Object.keys(editedFields).length > 0
    const navigate = useNavigate()

    const taxExemptionQuery = useGetTaxExemptionDetail({ taxExemptionId })
    const exemption = (taxExemptionQuery.data?.taxExemptions || [])[0] || {}
    const exemptionTypes = editedFields.exemption_type ?? exemption.exemption_type ?? ''
    const setExemptionTypes = (types: string) => {
        setEditedFields(() => ({
            exemption_type: types,
        }))
    }

    const previousLocationPathname: string | undefined = useLocation().state?.previousLocationPathname

    const deleteTaxExemption = useDeleteTaxExemption()
    const updateTaxExemption = useUpdateTaxExemption()

    const closeModal = () => {
        if (previousLocationPathname?.includes('tax-exemptions')) {
            navigate(-1)
            return
        }
        navigate(`/tax-exemptions?${searchParams.toString()}`)
    }

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setEditedFields((prev: any) => {
            const newState = structuredClone(prev)
            return {
                ...newState,
                [target.name]: target.value,
            }
        })
    }

    const handleSave = () => {
        updateTaxExemption.mutate(
            { taxExemption: { id: taxExemptionId, ...editedFields } },
            {
                onSuccess: () => {
                    setEditedFields({})
                },
            }
        )
    }
    const handleCancel = () => {
        setEditedFields({})
    }
    const handleDelete = () => {
        deleteTaxExemption.mutate(
            { taxExemptionId },
            {
                onSuccess: () => {
                    closeModal()
                },
            }
        )
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (e.target === modalRef.current) {
                closeModal()
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div
            ref={modalRef}
            className='fixed top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center z-50'
        >
            <div className='bg-bg1 dark:bg-darkbg1 max-w-[90%] max-h-[90%] min-w-[65%] min-h-[50%] p-6 rounded shadow-md relative'>
                <button className='absolute top-1 right-1 text-lg' onClick={closeModal}>
                    <FaTimes className='text-red' />
                </button>
                <div className='flex justify-between pb-1 border-b border-lightrey dark:border-darkgrey mb-2 min-h-[42px]'>
                    <h2 className='font-bold text-lg leading-tight flex flex-col'>
                        <span>Tax Exemption: {taxExemptionId}</span>
                    </h2>
                    {edited && (
                        <div className='flex gap-4 items-center'>
                            <FetchingSpinner isFetching={updateTaxExemption.isPending} />
                            <div
                                className={cn(
                                    'flex gap-4',
                                    updateTaxExemption.isPending && 'opacity-60 pointer-events-none'
                                )}
                            >
                                <Button onClick={handleSave} variant={'outline'}>
                                    Save Changes
                                </Button>
                                <Button className='' onClick={handleCancel} variant={'outline'}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
                <div className='grid grid-cols-2 gap-4 min-h-[280px]'>
                    <div className='mb-2'>
                        <div className='flex gap-2 mb-2 items-center'>
                            <h2 className='font-bold'>Customer Details </h2>
                            <Link
                                className='flex gap-2 items-center font-normal text-sm'
                                to={`/customers?customer_id=${exemption.customer_id}`}
                            >
                                <FaExternalLinkAlt />
                            </Link>
                        </div>
                        <div className='flex flex-col gap-4 w-full'>
                            <div
                                onClick={() => {
                                    if (exemption.calculated_full_name) {
                                        navigator.clipboard.writeText(exemption.calculated_full_name)
                                        sendToast({ message: `Copied: ${exemption.calculated_full_name}` })
                                    }
                                }}
                            >
                                <Input
                                    className='cursor-pointer'
                                    id='name'
                                    name='name'
                                    value={exemption.calculated_full_name}
                                    label='Name'
                                    type='text'
                                    onChange={() => {}}
                                    readOnly={true}
                                />
                            </div>
                            <div
                                onClick={() => {
                                    if (exemption.email) {
                                        navigator.clipboard.writeText(exemption.email)
                                        sendToast({ message: `Copied: ${exemption.email}` })
                                    }
                                }}
                            >
                                <Input
                                    className='cursor-pointer'
                                    id='email'
                                    name='email'
                                    value={exemption.email}
                                    label='Email'
                                    type='text'
                                    onChange={() => {}}
                                    readOnly={true}
                                />
                            </div>
                            <div
                                onClick={() => {
                                    if (exemption.phone) {
                                        navigator.clipboard.writeText(exemption.phone)
                                        sendToast({ message: `Copied: ${exemption.phone}` })
                                    }
                                }}
                            >
                                <Input
                                    className='cursor-pointer'
                                    id='phone'
                                    name='phone'
                                    value={exemption.phone}
                                    label='Phone'
                                    type='text'
                                    onChange={() => {}}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='mb-2'>
                        <h2 className='font-bold mb-2'>Exemption Details</h2>
                        <div className='flex flex-col gap-4'>
                            <Select
                                id='status'
                                name='status'
                                label='Status'
                                value={editedFields.status ?? exemption.status}
                                onChange={handleChange}
                            >
                                <option value='UNDER_REVIEW'>Under Review</option>
                                <option value='WAITING_FOR_CUSTOMER'>Waiting For Customer</option>
                                <option value='REJECTED'>Rejected</option>
                                <option value='ACCEPTED'>Accepted</option>
                            </Select>
                            <TaxExemptionSelector
                                exemptionTypes={exemptionTypes}
                                setExemptionTypes={setExemptionTypes}
                            />
                        </div>
                    </div>
                </div>
                <TaxFormList taxExemptionId={taxExemptionId} />
                {showDelete ? (
                    <div className='flex gap-4 absolute bottom-1 right-6 text-sm items-center'>
                        <FetchingSpinner isFetching={deleteTaxExemption.isPending} />
                        <div
                            className={cn(
                                'flex gap-2',
                                deleteTaxExemption.isPending && 'opacity-60 pointer-events-none'
                            )}
                        >
                            <Button
                                variant={'outline'}
                                size={'sm'}
                                className='text-sm text-danger p-1 h-fit'
                                onClick={handleDelete}
                            >
                                Delete Tax Exemption
                            </Button>
                            <Button
                                className='text-sm p-1 h-fit'
                                variant={'outline'}
                                size={'sm'}
                                onClick={() => setShowDelete(false)}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                ) : (
                    <button className='absolute bottom-2 right-2 text-lg' onClick={() => setShowDelete(true)}>
                        <FaTrash className='text-red' />
                    </button>
                )}
            </div>
        </div>
    )
}
