import { useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import { Button } from '../../../custom_components/component_Basics/Button'
import { cn } from '../../../helpers'
import { useDeleteFile } from '../../api/useQueries'
import { formatDate } from '../../helpers'
import { useLocation } from 'react-router-dom'

export default function CompanyFileV2({
    file,
    setShowCSVMapper,
    setSelectedFile,
}: {
    file: any
    setShowCSVMapper: any
    setSelectedFile: any
}) {
    const deleteFile = useDeleteFile()
    const [showDelete, setShowDelete] = useState(false)
    const location = useLocation()
    const isOnVendorsPage = location.pathname.includes('vendorsInfo')

    const handleDeleteFile = () => {
        deleteFile.mutate({ file })
    }
    return (
        <div
            className={cn(
                deleteFile.isPending && 'opacity-50',
                'text-[14px] grid grid-cols-[1fr_1fr_1fr_1fr_50px] border-b border-lightgrey dark:border-darkgrey hover:bg-lightgrey dark:hover:bg-darkness'
            )}
        >
            <a
                key={file.name}
                href={file.src}
                style={{ wordBreak: 'break-word' }}
                className='p-[8px] font-bold dark:text-offwhite'
            >
                {file.name}
            </a>
            <div
                style={{ wordBreak: 'break-word' }}
                className='flex gap-2 justify-between items-center px-2 font-bold dark:text-offwhite'
            >
                {file.type}
                {(file.type === 'Price List / SKUs' || file.type === 'FP Import Template') &&
                    !isOnVendorsPage &&
                    file.file_type === 'text/csv' && (
                        <Button
                            onClick={() => {
                                setSelectedFile(file)
                                setShowCSVMapper(true)
                            }}
                            variant={'outline'}
                            size={'sm'}
                        >
                            Create Products
                        </Button>
                    )}
            </div>
            <div style={{ wordBreak: 'break-word' }} className='p-[8px] font-bold dark:text-offwhite'>
                {file.file_type}
            </div>
            <div style={{ wordBreak: 'break-word' }} className='p-[8px] font-bold dark:text-offwhite'>
                {formatDate(new Date(file.created_at))}
            </div>
            {!showDelete && (
                <button className='self-center justify-self-end mr-2' onClick={() => setShowDelete(true)}>
                    <FaTrash className='text-red dark:text-lightred' />
                </button>
            )}
            {showDelete && (
                <div className='flex gap-[8px] self-center justify-self-end'>
                    <button
                        onClick={handleDeleteFile}
                        className={`${
                            deleteFile.isPending ? 'opacity-[0.5] pointer-events-none' : 'pointer-events-auto'
                        } shrink-0 text-[14px] leading-[1] py-[4px] w-fit px-[8px] rounded-[4px] bg-red dark:bg-lightred text-white font-bold uppercase`}
                    >
                        Delete
                    </button>
                    <button
                        onClick={() => setShowDelete(false)}
                        className={`${
                            deleteFile.isPending ? 'opacity-[0.5] pointer-events-none' : 'pointer-events-auto'
                        } text-[14px] leading-[1] py-[4px] w-fit px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey font-bold uppercase`}
                    >
                        Cancel
                    </button>
                </div>
            )}
        </div>
    )
}
