import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaTimes } from 'react-icons/fa'
import { FaTrashCan } from 'react-icons/fa6'
import { MdEdit } from 'react-icons/md'
import { useSearchParams } from 'react-router-dom'
import { vFetch } from '../../helpers'
import { useEffect, useState } from 'react'
import { setSelected } from '../../redux/selected'
import { articleCardDateFormatter } from '../helpers'
import ArticleView from '../views/ArticleView'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import AdminArticleView from '../views/AdminArticleView'

export default function ArticleUnderReviewCard({
    article,
    setSelectedArticle,
    setSelectedArticleView,
    setView,
}: {
    article: any
    setSelectedArticle: any
    setSelectedArticleView: any
    setView: any
}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [showEditButton, setShowEditButton] = useState(true)
    const [editArticle, setEditArticle] = useState(false)
    const [confirmCancel, setConfirmCancel] = useState(false)
    const handleSetArticle = (article: any) => {
        setSelectedArticle(article)
        setView('adminArticleView')
        setSearchParams((prev: any) => {
            // prev.set('adminArticleView')
            prev.set('adminArticleView', article.id)
            return prev
        })
    }

    // REACT QUERY //
    function useDeleteArticle() {
        const queryClient = useQueryClient()
        return useMutation({
            mutationFn: ({ id }: { id: any }) => {
                return vFetch('/v1/knowledge/articles', {
                    method: 'DELETE',
                    body: JSON.stringify({ id: id }),
                })
            },

            onSuccess: () => {
                handleCancel()
                return queryClient.invalidateQueries({
                    queryKey: [
                        ['knowledge', 'articles', 'adminPending'],
                        ['knowledge', 'articles', 'global'],
                    ],
                })
            },
            onSettled: () => {},
        })
    }
    const deleteArticle = useDeleteArticle()
    const handleDeleteArticle = (id: any) => {
        deleteArticle.mutate(
            { id },
            {
                onSuccess: () => {
                    // setSearchParams((prev: any) => {
                    //     prev.delete('articleID', `${id}`)
                    //     return prev
                    // })
                },
            }
        )
    }

    function handleCancel() {
        setShowEditButton(true)
        setEditArticle(false)
        setConfirmCancel(false)
    }
    const handleDeleteAttempt = () => {
        setConfirmCancel(true)
    }
    useEffect(() => {}, [])
    return (
        <>
            <div
                key={`articles-to-review${article.id}`}
                className='relative flex flex-col border bg-neutral-50 dark:bg-darkbg1 border-lightgrey dark:border-none 
            shadow-md dark:shadow-darkbg2 hover:shadow-xl rounded overflow-hidden p-4'
            >
                <button onClick={() => handleSetArticle(article)} className='h-full rounded'>
                    <img src={article.featured_image} className='object-cover max-h-[200px] h-full w-full rounded' />
                </button>
                <div className='absolute right-2 top-5'>
                    {/* {showEditButton ? (
                        <button
                            className='text-black dark:text-offwhite text-xl font-bold'
                            onClick={handleThreeDotsClick}
                        >
                            <BsThreeDotsVertical />
                        </button>
                    ) : (
                        <div>
                            <button
                                onClick={handleCancel} //
                                className='shrink-0 whitespace-nowrap
                                 dark:text-offwhite text-xl font-bold'
                            >
                                <FaTimes className='text-red w-[22px] h-[22px]' />
                            </button>
                        </div>
                    )} */}
                    {!showEditButton && !editArticle && !confirmCancel && (
                        <div className='absolute right-2 top-7 flex flex-col shrink-0 border shadow-md text-sm dark:border-darkgrey  whitespace-nowrap text-darkgrey dark:text-white bg-white dark:bg-darkbg1 rounded'>
                            <button
                                onClick={() => handleSetArticle(article)}
                                value='Edit'
                                className='flex gap-2 items-center dark:text-offwhite text-darkaccent m-2'
                            >
                                <MdEdit className='w-[20px]' />
                                Review Article
                            </button>
                            <button
                                value='Delete'
                                onClick={handleDeleteAttempt}
                                className='flex gap-2 items-center mb-2 mx-2 text-red/75 dark:text-offwhite'
                            >
                                <FaTrashCan className='w-[20px] text-red/75 dark:text-lightred text-sm' />
                                Delete Article
                            </button>
                        </div>
                    )}
                    {confirmCancel && (
                        <div className='absolute items-center right-2 top-7 flex flex-col shrink-0 border shadow-md text-sm dark:border-darkgrey  whitespace-nowrap text-darkgrey dark:text-white bg-white dark:bg-darkbg1 rounded'>
                            <p className='flex gap-2 items-center dark:text-offwhite text-darkaccent m-2'>
                                Are you sure?
                            </p>
                            <button
                                value='Yes - Delete'
                                onClick={() => handleDeleteArticle(article.id)}
                                className='p-1 px-[22px] rounded flex gap-2 items-center mb-1.5 mx-2 text-white dark:text-offwhite bg-red/75 dark:bg-lightred'
                            >
                                Yes
                            </button>
                            <button
                                value='No - Cancel'
                                onClick={handleCancel}
                                className='p-1 px-6 rounded flex gap-2 items-center mb-2 mx-2 text-white dark:text-offwhite bg-grey'
                            >
                                No
                            </button>
                        </div>
                    )}
                </div>
                <div className='flex flex-col pt-4'>
                    <button
                        className='text-md font-semibold leading-tight text-left'
                        onClick={() => handleSetArticle(article)}
                    >
                        {article.title}
                    </button>
                    <div className='opacity-50'>{article.department_label}</div>
                    <div className='mt-1 leading-tight line-clamp-3 text-left text-sm'>{article.excerpt}</div>
                    <div className='p-1  w-full border border-t-0 border-x-0 dark:border-darkgrey'></div>
                    <div className='flex items-center gap-2'>
                        <div className='mt-3 flex shrink-0 rounded-full items-center justify-center w-[34px] h-[34px] text-lg font-bold text-white dark:text-offwhite bg-orange-500'>
                            {article.user_firstName[0]}
                            {article.user_lastName[0]}
                        </div>
                        <div>
                            <div className='mt-4 font-semibold text-sm leading-none'>
                                {article.user_firstName} {article.user_lastName}
                            </div>
                            <div className='mt-0.5 flex flex-row items-center gap-2'>
                                <div className='text-sm'>
                                    {articleCardDateFormatter.format(new Date(article.created_at))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
