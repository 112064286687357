import { useEffect, useState } from 'react'
import Input from '../../procurement/components/Input'
import { useSelector } from 'react-redux'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { vFetch } from '../../helpers'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import { useAllUsersQuery } from '../../users/api/useQueries'
import { UserInit } from '../../users/users.types'
import { Button } from '../../custom_components/component_Basics/Button'
import { useSearchParams } from 'react-router-dom'
import { BsFillPinFill, BsPin, BsPinFill } from 'react-icons/bs'
import { useQueryClient } from '@tanstack/react-query'

export default function MyContributionsEditArticleView({
    setView,
    selectedArticle,
    setSelectedArticle,
}: {
    setView: any
    selectedArticle: any
    setSelectedArticle: any
}) {
    const allUsersQuery = useAllUsersQuery()
    const { users }: { users: UserInit[] } = allUsersQuery?.data || { users: [] }
    const user = users?.find((user) => {
        return user.id
    })
    const [departments, setDepartments] = useState<any>([])
    const [searchParams, setSearchParams] = useSearchParams()
    const myEditArticle = searchParams.get('myEditArticle')
    const [editableArticleTitle, setEditableArticleTitle] = useState<undefined | string>()
    const [editableArticleExcerpt, setEditableArticleExcerpt] = useState<undefined | string>()
    const [editableArticleContent, setEditableArticleContent] = useState<undefined | string>(selectedArticle.content)
    const [toggleVisibilityDropdown, setToggleVisibilityDropdown] = useState(true)
    const [toggleDepartmentDropdown, setToggleDepartmentDropdown] = useState(true)
    const [toggleAuthorDropdown, setToggleAuthorDropdown] = useState(true)
    const [selectedDepartmentID, setSelectedDepartmentID] = useState<undefined | number>(selectedArticle.department_id) //
    const [selectedAuthorID, setSelectedAuthorID] = useState<undefined | number>(selectedArticle.user_id)
    const [articleHidden, setArticleHidden] = useState<any>(selectedArticle.hidden)
    const currentUser = useSelector((state: any) => state.user)
    const [editableArticle, setEditableArticle] = useState<undefined | string>()
    const isAdmin = currentUser.type === 'Admin' || currentUser.type === 'SuperAdmin'
    // const isAdmin = currentUser.type === 'client'
    const [file, setFile] = useState(selectedArticle.featured_image)
    const articleStatus = isAdmin ? 'Published' : 'Pending'
    const [isPinned, setIsPinned] = useState(selectedArticle.pinned)
    const [article, setArticle] = useState<any>(selectedArticle)
    const selectedAuthor = selectedAuthorID ? users.find((users: any) => users.id === selectedAuthorID) : null //
    const selectedGlobalDepartment: any = 'Global'
    const queryClient = useQueryClient()

    const selectedDepartment =
        selectedDepartmentID === 0
            ? { label: 'Global' }
            : departments.find((department: any) => department.id === selectedDepartmentID)

    const handleImageSelect = (e: any) => {
        const data = new FormData()
        data.append('images', e.target.files[0], e.target.files[0].name)
        vFetch('/v1/knowledge/articles/images', {
            method: 'POST',
            body: data,
            contentType: 'skip',
            cb: (res: any) => {
                if (res.success) {
                    setFile(res.uploadedImages.files[0].originalSource)
                }
                e.target.files = null
            },
        })
    }
    const getDepartments = () => {
        vFetch('/v1/knowledge/departments', {
            cb: (res: any) => {
                if (res.success) {
                    setDepartments(res.departments)
                }
            },
        })
    }
    const getArticles = async () => {
        vFetch(`/v1/knowledge/articles?id=${selectedArticle.id}`, {
            cb: (res: any) => {
                setEditableArticle(undefined)
                if (res.success) {
                    const foundArticle = res.articles.find((article: any) => article.id == myEditArticle)
                    if (foundArticle) {
                        setSelectedArticle(foundArticle)
                        setEditableArticleContent(foundArticle.content)
                    }
                }
            },
        })
    }
    const handleUpdateArticle = () => {
        vFetch('/v1/knowledge/articles', {
            method: 'PUT',
            body: JSON.stringify({
                id: selectedArticle.id,
                title: editableArticleTitle,
                featured_image: file || '/logo.png',
                excerpt: editableArticleExcerpt,
                content: editableArticleContent,
                hidden: articleHidden,
                pinned: isPinned,
                status: articleStatus,
                user_id: selectedAuthorID,
                department_id: selectedDepartmentID,
            }),
            cb: (res: any) => {
                if (res.success) {
                    setView('myContributions')
                    setEditableArticleTitle(undefined)
                    setEditableArticleExcerpt(undefined)
                    setEditableArticleContent(undefined)
                    setSelectedArticle(undefined)
                    setSearchParams((prev: any) => {
                        prev.delete('editArticleView')
                        prev.delete('myContributions')
                        prev.delete('departmentView')
                        prev.delete('departmentID')
                        prev.delete('myEditArticle')
                        return prev
                    })
                    return queryClient.invalidateQueries({
                        queryKey: ['knowledge', 'articles', 'global'],
                    })
                }
            },
        })
    }
    const handleVisibilityDropdownClick = () => {
        setToggleVisibilityDropdown((prev) => !prev)
    }
    const handleDepartmentDropdownClick = () => {
        setToggleDepartmentDropdown((prev) => !prev)
    }
    const handleAuthorDropdownClick = () => {
        setToggleAuthorDropdown((prev) => !prev)
    }
    const handleUserChoseHidden = () => {
        setArticleHidden(true)
        handleVisibilityDropdownClick()
    }
    const handleUserChoseVisible = () => {
        setArticleHidden(false)
        handleVisibilityDropdownClick()
    }

    const handleGlobalDepartmentSelection = () => {
        setSelectedDepartmentID(0) //
        setToggleDepartmentDropdown((prev) => !prev) //
    }
    const handleDepartmentSelection = (department: any) => {
        setSelectedDepartmentID(department.id)
        setToggleDepartmentDropdown((prev) => !prev)
    }

    const handleAuthorSelection = (user: any) => {
        setSelectedAuthorID(user.id)
        setToggleAuthorDropdown((prev) => !prev)
    }

    const handleBackButton = () => {
        setView('myContributions')
        setSelectedArticle(undefined)
        setSearchParams((prev: any) => {
            prev.delete('myArticleID')
            prev.delete('myEditArticle')
            return prev
        })
    }
    useEffect(() => {
        getDepartments()
        getArticles()
    }, [])

    return (
        <div className='h-full overflow-auto pr-2'>
            <Button onClick={handleBackButton} variant={'outline'} className='mx-auto mt-2 text-xs'>
                BACK
            </Button>
            <div className='flex justify-between items-center'>
                <div className='mt-4 text-2xl font-bold'>Edit Article</div>
                <div>
                    <button
                        onClick={handleUpdateArticle}
                        className='mt-6 py-0 px-2 shrink-0 font-bold whitespace-nowrap bg-blue text-offwhite dark:bg-accent dark:text-darkaccent rounded'
                    >
                        Update Article
                    </button>
                </div>
            </div>

            <div className='mt-6 gap-4 flex justify-between items-start'>
                {/* div holding inputs and featured image */}
                <div className='relative w-full border border-lightgrey dark:border-darkgrey'>
                    <div className='z-[2] absolute right-5 top-1'>
                        {isAdmin && (
                            <>
                                {isPinned ? (
                                    <button>
                                        <BsFillPinFill
                                            onClick={() => {
                                                setIsPinned(false)
                                            }}
                                            className=' w-6 h-6 text-orange-400'
                                        />{' '}
                                    </button>
                                ) : (
                                    <button>
                                        <BsPin
                                            onClick={() => {
                                                setIsPinned(true)
                                            }}
                                            className=' w-6 h-6 text-grey'
                                        />
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                    <div className='p-4 h-fit sticky top-[50px]'>
                        <p className='text-xs font-bold'>TITLE</p>
                        <Input
                            type='text'
                            id='title'
                            name='title'
                            value={editableArticleTitle ?? selectedArticle.title}
                            onChange={({ target }) => {
                                setEditableArticleTitle(target.value)
                            }}
                            className='mt-1 font-bold py-2 pl-2'
                        ></Input>
                        <div className='mt-3 flex gap-3'>
                            <div className='w-full relative rounded'>
                                <p className='text-xs font-bold'>VISIBILITY</p>
                                <div className=' bg-lightgrey dark:bg-darkbg1 rounded'>
                                    <div
                                        className={`flex justify-between w-full bg-lightgrey rounded dark:bg-darkbg2 mt-1 ${
                                            toggleVisibilityDropdown
                                                ? ''
                                                : 'rounded-b-none border border-b-0 dark:border-darkgrey'
                                        }`}
                                    >
                                        <div className='font-bold py-1 pl-2'>
                                            {articleHidden ? 'Hidden' : 'Visible'}
                                        </div>

                                        {toggleVisibilityDropdown ? (
                                            <button onClick={handleVisibilityDropdownClick}>
                                                <FaAngleDown className='text-blue dark:text-offwhite w-[25px] bg-lightgrey dark:bg-darkbg2' />
                                            </button>
                                        ) : (
                                            <button onClick={handleVisibilityDropdownClick} className=''>
                                                <FaAngleUp className='text-blue dark:text-offwhite w-5 bg-lightgrey dark:bg-darkbg2' />
                                            </button>
                                        )}
                                    </div>
                                    {!toggleVisibilityDropdown && (
                                        <div className='absolute z-10 w-full overflow-auto bg-lightgrey dark:bg-darkbg2 border dark:border-darkgrey'>
                                            <button
                                                onClick={handleUserChoseVisible}
                                                className='text-left pl-2 w-full font-bold py-1 '
                                            >
                                                Visibile
                                            </button>
                                            <button
                                                onClick={handleUserChoseHidden}
                                                className='text-left pl-2 w-full font-bold py-1 border-t dark:border-darkgrey'
                                            >
                                                Hidden
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='w-full'>
                                <p className='text-xs font-bold'>DEPARTMENT</p>
                                <div
                                    className={`flex items-center w-full bg-lightgrey rounded dark:bg-darkbg2 mt-1  ${
                                        toggleDepartmentDropdown
                                            ? ''
                                            : 'rounded-b-none dark:border-darkgrey border border-b-0'
                                    }`}
                                >
                                    <div className=' w-full relative'>
                                        <div className='flex items-center'>
                                            <div
                                                className={`flex items-center justify-between bg-lightgrey dark:bg-darkbg2 w-full font-bold py-1 pl-2 rounded
                                                    ${toggleDepartmentDropdown ? '' : 'rounded-b-none'}
                                                `}
                                            >
                                                {selectedDepartment ? selectedDepartment.label : 'Select Department'}
                                            </div>
                                            <div>
                                                {toggleDepartmentDropdown ? (
                                                    <button onClick={handleDepartmentDropdownClick}>
                                                        <FaAngleDown className='text-blue dark:text-offwhite w-[25px] bg-lightgrey dark:bg-darkbg2' />
                                                    </button>
                                                ) : (
                                                    <button onClick={handleDepartmentDropdownClick} className='z-50'>
                                                        <FaAngleUp className='text-blue dark:text-offwhite w-5 bg-lightgrey dark:bg-darkbg2' />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        {!toggleDepartmentDropdown && (
                                            <div className='absolute z-10 w-full overflow-auto bg-lightgrey dark:bg-darkbg2 border dark:border-darkgrey'>
                                                {departments?.map((department: any) => (
                                                    <button
                                                        onClick={() => handleDepartmentSelection(department)}
                                                        className='text-left pl-2 w-full font-bold py-1 border-t dark:border-darkgrey'
                                                    >
                                                        {department.label}
                                                    </button>
                                                ))}
                                                {isAdmin && (
                                                    <button
                                                        onClick={handleGlobalDepartmentSelection} //
                                                        className='text-left pl-2 w-full font-bold py-1'
                                                    >
                                                        Global
                                                    </button>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {isAdmin && (
                                <div className='w-full'>
                                    <p className='text-xs font-bold'>AUTHOR</p>
                                    <div className='relative flex items-center w-full bg-lightgrey dark:bg-darkbg2 rounded mt-1'>
                                        <div className=' w-full'>
                                            <div
                                                className={`flex items-center justify-between w-full font-bold py-1 pl-2 rounded
                                                ${
                                                    toggleAuthorDropdown
                                                        ? ''
                                                        : 'rounded-b-none border border-b-0 dark:border-darkgrey'
                                                }
                                            `}
                                            >
                                                {selectedAuthor
                                                    ? selectedAuthor.first_name + ' ' + selectedAuthor.last_name
                                                    : 'Select Author'}
                                                <div>
                                                    {toggleAuthorDropdown ? (
                                                        <button onClick={handleAuthorDropdownClick}>
                                                            <FaAngleDown className='text-blue dark:text-offwhite w-[25px]' />
                                                        </button>
                                                    ) : (
                                                        <button onClick={handleAuthorDropdownClick} className='z-50'>
                                                            <FaAngleUp className='text-blue dark:text-offwhite w-5' />
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                            {!toggleAuthorDropdown && (
                                                <div className='absolute z-10 w-full max-h-56 overflow-auto border border-t-0 dark:border-darkgrey'>
                                                    {users?.map((user: any) => (
                                                        <button
                                                            onClick={() => handleAuthorSelection(user)}
                                                            className='text-left pl-2 w-full bg-lightgrey dark:bg-darkbg2 font-bold py-1 border-t dark:border-darkgrey'
                                                        >
                                                            {user.first_name} {user.last_name}
                                                        </button>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='mt-3'>
                            <p className='text-xs font-bold'>EXCERPT</p>
                            <textarea
                                value={editableArticleExcerpt ?? selectedArticle.excerpt}
                                onChange={({ target }) => {
                                    setEditableArticleExcerpt(target.value)
                                }}
                                className='mt-1 min-h-[100px] max-h-[400px] w-[100%] p-[8px] max-w-none border-0
                    rounded focus:outline-none bg-lightgrey  dark:bg-darkness
                     dark:text-offwhite  overscroll-contain py-2 pl-2'
                            ></textarea>
                        </div>
                    </div>
                </div>

                <div className='w-full max-w-[390px] h-full border border-lightgrey dark:border-darkgrey rounded'>
                    <div className='mx-3 mb-4 mt-2'>
                        <p className='text-sm font-bold'>FEATURED IMAGE</p>
                        <img
                            // src={file} /logo512.png  fpdash-client/src/logo.png
                            src={file ? file : '/logo.png'}
                            className='border border-lightgrey dark:border-darkgrey rounded p-1 mt-1'
                        />
                        <p className='text-xs font-bold mt-2'>UPLOAD NEW FEATURED IMAGE</p>
                        <div
                            className='mt-2 p-2 gap-1 text-sm flex border border-lightgrey dark:border-darkgrey bg-lightgrey dark:bg-darkness
                     dark:text-offwhite rounded'
                        >
                            <input type='file' onChange={handleImageSelect} />
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className='mt-4 mb-4 border border-lightgrey dark:border-darkgrey rounded'>
                    <div className='p-4'>
                        <p className='mb-3 text-xs font-bold'>CONTENT</p>
                        <style>{`
                            .dark .ql-toolbar .ql-stroke {
                                fill: none; 
                                stroke: #fff;
                            }
                            .dark .ql-toolbar .ql-fill {
                                fill: #fff; 
                                stroke: none;
                            }
                            .dark .ql-toolbar .ql-picker {
                                color: #fff;
                            }
                            .dark .ql-toolbar,
                            .dark .ql-toolbar * {
                                background-color: var(--darkBackgroundPrimary);
                            }
                            .ql-editor {
                                min-height: 400px;
                                background-color: var(--tw-bg-opacity);
                            }
                            .dark .ql-editor {
                                min-height: 400px;
                                max-height: 450px;
                                background-color: var(--darkBackgroundSecondary);
                            }
                            .quill, .quill * {
                             border-color: #e5e7eb;
                            }
                            .dark .quill, .dark .quill * {
                                border-color: #4A4A4A;
                            }
                            .quill {
                                border-radius: 4px;
                                border-color: #e5e7eb;
                            }
                        `}</style>

                        <ReactQuill
                            theme='snow'
                            value={editableArticleContent}
                            onChange={(value) => setEditableArticleContent(value)}
                            className=' dark:bg-darkness
                            dark:text-offwhite'
                        />

                        <button
                            onClick={handleUpdateArticle}
                            className='mt-6 py-0 px-2 shrink-0 font-bold whitespace-nowrap bg-blue text-offwhite dark:bg-accent dark:text-darkaccent rounded'
                        >
                            Update Article
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
