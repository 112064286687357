import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { vFetch } from '../helpers'

export default function Modal({ vendors, supplier, setSupplier, setNeedsRefresh }) {
    const [tempSupplier, setTempSupplier] = useState({ ...supplier })
    const [show, setShow] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const [emailString, setEmailString] = useState('')
    const [emailArr, setEmailArr] = useState([])
    const [search, setSearch] = useState('')
    const [filteredVendors, setFilteredVendors] = useState(vendors)
    const [selectedVendors, setSelectedVendors] = useState([])
    const [products, setProducts] = useState([])
    const [filteredProducts, setFilteredProducts] = useState([])
    const [skuSearch, setSkuSearch] = useState('')
    const [skuMap, setSkuMap] = useState(supplier && supplier.sku_mapping ? JSON.parse(supplier.sku_mapping) : {})
    const navigate = useNavigate()
    useEffect(() => {
        const filterVendors = (vendor) => {
            if (vendor.toLowerCase().includes(search.toLowerCase())) {
                return true
            }
            return false
        }
        const sortVendors = (a, b) => {
            if (a.toLowerCase() === search.toLowerCase() && b.toLowerCase() !== search.toLowerCase()) {
                return -1
            }
            if (a.toLowerCase() !== search.toLowerCase() && b.toLowerCase() === search.toLowerCase()) {
                return 1
            }
            if (a.toLowerCase().startsWith(search.toLowerCase()) && !b.toLowerCase().startsWith(search.toLowerCase())) {
                return -1
            } else if (
                !a.toLowerCase().startsWith(search.toLowerCase()) &&
                b.toLowerCase().startsWith(search.toLowerCase())
            ) {
                return 1
            }
            return 0
        }
        setFilteredVendors([...vendors].filter(filterVendors).sort(sortVendors).slice(0, 1))
    }, [search])
    useEffect(() => {
        if (supplier) {
            setSkuMap(supplier.sku_mapping ? JSON.parse(supplier.sku_mapping) : {})
            if (supplier.name) {
                setIsNew(false)
            } else {
                setIsNew(true)
            }
            setShow(true)
            setTempSupplier(supplier)
            try {
                setEmailString(JSON.parse(supplier.auto_email_address).join(','))
                setEmailArr(JSON.parse(supplier.auto_email_address))
                setSelectedVendors(supplier.vendor_names ? JSON.parse(supplier.vendor_names) : [])
            } catch {
                setEmailString('')
                setEmailArr([])
                setSelectedVendors([])
            }
        } else {
            setShow(false)
            setTempSupplier({})
            setEmailString('')
            setEmailArr([])
            setSelectedVendors([])
        }
    }, [supplier])
    useEffect(() => {
        vFetch(
            `/v1/products/vendor?vendors=${selectedVendors
                .map((v) => encodeURIComponent(v))
                .join(',')}&fields=${encodeURIComponent(`p.title,v.sku,p.admin_graphql_api_id AS gid`)}`,
            {
                cb: (res) => {
                    if (res.success) {
                        setProducts(res.products)
                        setFilteredProducts(res.products)
                    }
                },
            }
        )
        setSkuSearch('')
    }, [selectedVendors])
    useEffect(() => {
        setFilteredProducts(products.filter((p) => p.sku && p.sku.toLowerCase().includes(skuSearch.toLowerCase())))
    }, [skuSearch])
    const handleChangeSupplier = ({ target }) => {
        if (target.type === 'checkbox') {
            setTempSupplier({
                ...tempSupplier,
                [target.name]: target.checked,
            })
        } else if (target.id === 'accepts_email') {
            setTempSupplier({
                ...tempSupplier,
                accepts_email: target.checked,
                accepts_edi: !target.checked,
            })
        } else if (target.id === 'accepts_edi') {
            setTempSupplier({
                ...tempSupplier,
                accepts_email: !target.checked,
                accepts_edi: target.checked,
            })
        } else if (
            target.name === 'production_as2_station_id' ||
            target.name === 'test_as2_station_id' ||
            target.name === 'production_edi_id' ||
            target.name === 'test_edi_id'
        ) {
            setTempSupplier({
                ...tempSupplier,
                edi_info: {
                    ...tempSupplier.edi_info,
                    [target.name]: target.value,
                },
            })
        } else {
            setTempSupplier({
                ...tempSupplier,
                [target.name]: target.value,
            })
        }
    }
    const handleFormatEmails = ({ target }) => {
        setEmailString(target.value)
        setEmailArr(
            target.value
                .replaceAll('\n', ' ')
                .split(/[ ,]+/)
                .filter((e) => e.match(/^\S+@\S+\.\S+$/))
        )
    }
    const handleRemoveEmail = (index) => {
        const newEmailArr = [...emailArr]
        newEmailArr.splice(index, 1)
        const newEmailString = newEmailArr.join(', ')
        setEmailArr(newEmailArr)
        setEmailString(newEmailString)
    }
    const handleAddRemoveVendor = (currentVendor) => {
        if (selectedVendors.find((v) => v === currentVendor)) {
            setSelectedVendors(selectedVendors.filter((v) => v !== currentVendor))
        } else {
            const newVendors = [...selectedVendors]
            newVendors.push(currentVendor)
            setSelectedVendors(newVendors)
        }
        setSearch('')
    }
    const handleSubmit = () => {
        vFetch(`/v1/suppliers`, {
            method: isNew ? 'POST' : 'PUT',
            body: JSON.stringify({
                name: tempSupplier.name,
                country: tempSupplier.country,
                currency: tempSupplier.currency,
                contact_name: tempSupplier.contact_name,
                auto_email_address: emailArr,
                vendor_names: selectedVendors,
                notes: tempSupplier.notes,
                show_cost: tempSupplier.show_cost,
                accepts_email: tempSupplier.accepts_email,
                accepts_edi: tempSupplier.accepts_edi,
                edi_info: tempSupplier.edi_info,
                sku_mapping: skuMap,
                test_mode: tempSupplier.test_mode,
                previous_name: isNew ? null : supplier.name,
            }),
            cb: (res) => {
                if (res.success) {
                    setSupplier(null)
                    setNeedsRefresh(true)
                }
            },
        })
    }
    const handleDelete = () => {
        const confirmation = window.confirm('Are you sure?')
        if (confirmation) {
            vFetch(`/v1/suppliers`, {
                method: 'DELETE',
                body: JSON.stringify({
                    name: supplier.name,
                }),
                cb: (res) => {
                    if (res.success) {
                        setSupplier(null)
                        setNeedsRefresh(true)
                    }
                },
            })
        }
    }
    const handleNewPO = () => {
        navigate(`/orders/purchase-orders/new?supplier=${encodeURIComponent(supplier.name)}`)
    }
    return (
        <>
            {show && (
                <div className='fixed top-0 left-0 w-full h-full overflow-hidden bg-[rgb(0,0,0,0.5)] grid place-items-center z-[50]'>
                    <div className='dark:bg-darkaccent bg-white rounded-[4px] shadow-small p-[32px] relative w-[90%] max-w-[1200px] h-[90%] overflow-auto flex flex-col gap-[4px]'>
                        <div
                            onClick={() => setSupplier(null)}
                            className='w-[24px] h-[24px] leading-[1] text-[32px] font-bold text-red absolute top-[0px] right-0 cursor-pointer'
                        >
                            &times;
                        </div>
                        <div className='flex justify-between mb-[16px] border-b border-grey pb-[16px]'>
                            <h2 className='dark:text-offwhite uppercase text-[20px] tracking-[2px] font-bold'>
                                {isNew ? 'New Supplier' : `Edit Supplier: ${supplier && supplier.name}`}
                            </h2>
                            {!isNew && (
                                <button
                                    onClick={handleNewPO}
                                    className='ml-auto bg-blue text-white dark:bg-accent text-[14px] dark:text-darkness leading-[1] font-black py-[4px] px-[8px] uppercase rounded-[4px]'
                                >
                                    + New PO
                                </button>
                            )}
                            {!isNew && (
                                <button
                                    onClick={handleDelete}
                                    className='ml-[8px] border-red dark:border-lightred border-2 bg-red dark:bg-[rgb(255,0,0,0.75)] text-[14px] text-offwhite leading-[1] font-black py-[4px] px-[8px] uppercase rounded-[4px]'
                                >
                                    Delete
                                </button>
                            )}
                        </div>
                        <div className='flex gap-[32px] w-full'>
                            <div className='flex flex-col gap-[4px] w-full'>
                                <div className='flex gap-[8px]'>
                                    <div className='flex flex-col w-full'>
                                        <label className='text-[12px] dark:text-offwhite uppercase font-bold'>
                                            Name
                                        </label>
                                        <input
                                            value={tempSupplier.name}
                                            type='text'
                                            className='bg-lightgrey dark:bg-darkness dark:text-white p-[4px] px-[6px] rounded-[4px] w-full'
                                            name='name'
                                            onChange={handleChangeSupplier}
                                        />
                                    </div>
                                    <div className='flex flex-col'>
                                        <label className='text-[12px] dark:text-offwhite uppercase font-bold'>
                                            Country
                                        </label>
                                        <select
                                            value={tempSupplier.country}
                                            className='bg-lightgrey dark:bg-darkness dark:text-white p-[4px] px-[6px] rounded-[4px]'
                                            name='country'
                                            onChange={handleChangeSupplier}
                                        >
                                            <option value='United States'>United States</option>
                                            <option value='Canada'>Canada</option>
                                        </select>
                                    </div>
                                    <div className='flex flex-col'>
                                        <label className='text-[12px] dark:text-offwhite uppercase font-bold w-[80px]'>
                                            Currency
                                        </label>
                                        <select
                                            value={tempSupplier.currency}
                                            className='bg-lightgrey dark:bg-darkness dark:text-white p-[4px] px-[6px] rounded-[4px]'
                                            name='name'
                                            onChange={handleChangeSupplier}
                                        >
                                            <option value='USD'>USD</option>
                                            <option value='CAD'>CAD</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='flex flex-col'>
                                    <label className='text-[12px] dark:text-offwhite uppercase font-bold'>
                                        Contact Name
                                    </label>
                                    <input
                                        value={tempSupplier.contact_name}
                                        type='text'
                                        className='bg-lightgrey dark:bg-darkness dark:text-white p-[4px] px-[6px] rounded-[4px]'
                                        name='contact_name'
                                        onChange={handleChangeSupplier}
                                    />
                                </div>
                                <div className='relative flex flex-col'>
                                    <label className='text-[12px] dark:text-offwhite uppercase font-bold'>
                                        Email Addresses
                                    </label>
                                    <textarea
                                        className='bg-lightgrey z-[2] w-full dark:bg-darkness dark:text-offwhite border-0 focus:outline-none !h-[100px] mb-[4px]'
                                        onChange={handleFormatEmails}
                                        value={emailString}
                                    ></textarea>
                                    <div className='flex flex-wrap gap-[8px]'>
                                        {emailArr.map((e, index) => (
                                            <div className='relative py-[2px] px-[8px] rounded-[4px] text-white dark:text-black bg-fire font-bold text-[14px]'>
                                                <div
                                                    onClick={() => handleRemoveEmail(index)}
                                                    className='font-bold absolute top-0 right-[3px] leading-[1] text-[12px] cursor-pointer'
                                                >
                                                    &times;
                                                </div>
                                                {e}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col gap-[4px] w-full'>
                                <div className='flex flex-col  gap-[4px] w-full'>
                                    <div className='flex flex-col w-full'>
                                        <label className='text-[12px] dark:text-offwhite uppercase font-bold'>
                                            Affected Brands
                                        </label>
                                        <div className='bg-grey dark:bg-darkness rounded-[4px] overflow-hidden w-full relative'>
                                            <input
                                                className='w-full bg-lightgrey dark:text-white placeholder-current dark:bg-faintplus p-[4px] focus:outline-none'
                                                type='text'
                                                placeholder='Search'
                                                value={search}
                                                onChange={({ target }) => setSearch(target.value)}
                                            />
                                            <div className='w-full bg-grey dark:bg-darkness z-[5] absolute top-[32px] cursor-pointer'>
                                                {search.length > 0 &&
                                                    filteredVendors.map((v) => (
                                                        <div
                                                            onClick={() => handleAddRemoveVendor(v)}
                                                            className='flex gap-[8px] dark:text-offwhite p-[4px] items-center'
                                                        >
                                                            <div
                                                                className={`shrink-0 w-[16px] h-[16px] rounded-[4px] border border-offwhite ${
                                                                    selectedVendors.includes(v)
                                                                        ? 'dark:bg-offwhite'
                                                                        : ''
                                                                }`}
                                                            >
                                                                &nbsp;
                                                            </div>
                                                            <span className='text-white dark:text-offwhite font-bold'>
                                                                {v}
                                                            </span>
                                                        </div>
                                                    ))}
                                            </div>
                                            <div className='flex flex-wrap gap-[8px] p-[8px] min-h-[41px]'>
                                                {selectedVendors.map((v) => (
                                                    <div className='relative py-[2px] px-[8px] rounded-[4px] text-white dark:text-black bg-fire font-bold text-[14px]'>
                                                        <div
                                                            onClick={() => handleAddRemoveVendor(v)}
                                                            className='font-bold absolute top-0 right-[3px] leading-[1] text-[12px] cursor-pointer'
                                                        >
                                                            &times;
                                                        </div>
                                                        {v}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex flex-col w-full'>
                                        <label className='text-[12px] dark:text-offwhite uppercase font-bold'>
                                            Default PO Notes
                                        </label>
                                        <textarea
                                            className='z-[2] w-full bg-lightgrey dark:bg-darkness dark:text-offwhite border-0 focus:outline-none !h-[100px]'
                                            value={tempSupplier.notes}
                                            name='notes'
                                            onChange={handleChangeSupplier}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-[4px]'>
                                    <div className='text-[12px] dark:text-offwhite uppercase font-bold'>
                                        Supplier Settings
                                    </div>
                                    <div className='flex gap-[8px]'>
                                        <div className='flex gap-[4px] p-[4px] rounded-[4px] dark:bg-darkness w-fit'>
                                            <input
                                                checked={tempSupplier.show_cost}
                                                type='checkbox'
                                                className='dark:bg-darkness dark:text-white p-[4px] px-[6px] rounded-[4px] cursor-pointer'
                                                name='show_cost'
                                                id='show_cost'
                                                onChange={handleChangeSupplier}
                                            />
                                            <label
                                                className='dark:text-offwhite font-bold cursor-pointer'
                                                htmlFor='show_cost'
                                            >
                                                Show cost
                                            </label>
                                        </div>
                                        <div className='flex gap-[4px] p-[4px] rounded-[4px] dark:bg-darkness w-fit'>
                                            <input
                                                checked={tempSupplier.test_mode}
                                                type='checkbox'
                                                className='dark:bg-darkness dark:text-white p-[4px] px-[6px] rounded-[4px] cursor-pointer'
                                                name='test_mode'
                                                id='test_mode'
                                                onChange={handleChangeSupplier}
                                            />
                                            <label
                                                className='dark:text-offwhite font-bold cursor-pointer'
                                                htmlFor='test_mode'
                                            >
                                                Test mode
                                            </label>
                                        </div>
                                        <div className='flex gap-[4px] p-[4px] rounded-[4px] dark:bg-darkness w-fit'>
                                            <input
                                                checked={tempSupplier.accepts_email}
                                                type='radio'
                                                className='dark:bg-darkness dark:text-white p-[4px] px-[6px] rounded-[4px] cursor-pointer'
                                                name='send_type'
                                                id='accepts_email'
                                                onChange={handleChangeSupplier}
                                            />
                                            <label
                                                className='dark:text-offwhite font-bold cursor-pointer'
                                                htmlFor='accepts_email'
                                            >
                                                Email
                                            </label>
                                        </div>
                                        <div className='flex gap-[4px] p-[4px] rounded-[4px] dark:bg-darkness w-fit'>
                                            <input
                                                checked={tempSupplier.accepts_edi}
                                                type='radio'
                                                className='dark:bg-darkness dark:text-white p-[4px] px-[6px] rounded-[4px] cursor-pointer'
                                                name='send_type'
                                                id='accepts_edi'
                                                onChange={handleChangeSupplier}
                                            />
                                            <label
                                                className='dark:text-offwhite font-bold cursor-pointer'
                                                htmlFor='accepts_edi'
                                            >
                                                EDI
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {tempSupplier.accepts_edi && (
                            <div className='flex flex-col w-full gap-[4px] mt-[4px] mb-[8px]'>
                                <div className='flex gap-[8px]'>
                                    <div className='flex flex-col w-full'>
                                        <label className='text-[12px] dark:text-offwhite uppercase font-bold'>
                                            Production AS2 Station ID
                                        </label>
                                        <input
                                            value={tempSupplier.edi_info.production_as2_station_id}
                                            type='text'
                                            className='bg-lightgrey dark:bg-darkness dark:text-white p-[4px] px-[6px] rounded-[4px]'
                                            name='production_as2_station_id'
                                            onChange={handleChangeSupplier}
                                        />
                                    </div>
                                    <div className='flex flex-col w-full'>
                                        <label className='text-[12px] dark:text-offwhite uppercase font-bold'>
                                            Test AS2 Station ID
                                        </label>
                                        <input
                                            value={tempSupplier.edi_info.test_as2_station_id}
                                            type='text'
                                            className='bg-lightgrey dark:bg-darkness dark:text-white p-[4px] px-[6px] rounded-[4px]'
                                            name='test_as2_station_id'
                                            onChange={handleChangeSupplier}
                                        />
                                    </div>
                                </div>
                                <div className='flex gap-[8px]'>
                                    <div className='flex flex-col w-full'>
                                        <label className='text-[12px] dark:text-offwhite uppercase font-bold'>
                                            Production EDI ID
                                        </label>
                                        <input
                                            value={tempSupplier.edi_info.production_edi_id}
                                            type='text'
                                            className='bg-lightgrey dark:bg-darkness dark:text-white p-[4px] px-[6px] rounded-[4px]'
                                            name='production_edi_id'
                                            onChange={handleChangeSupplier}
                                        />
                                    </div>
                                    <div className='flex flex-col w-full'>
                                        <label className='text-[12px] dark:text-offwhite uppercase font-bold'>
                                            Test EDI ID
                                        </label>
                                        <input
                                            value={tempSupplier.edi_info.test_edi_id}
                                            type='text'
                                            className='bg-lightgrey dark:bg-darkness dark:text-white p-[4px] px-[6px] rounded-[4px]'
                                            name='test_edi_id'
                                            onChange={handleChangeSupplier}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {products.length > 0 && (
                            <>
                                <div className='flex gap-[8px] items-center'>
                                    <p className='text-[12px] dark:text-offwhite uppercase font-bold shrink-0'>
                                        Alternate SKU Mapping
                                    </p>
                                    <input
                                        className='w-full p-[4px] bg-lightgrey rounded-[4px]'
                                        placeholder='Search SKUs'
                                        type='text'
                                        value={skuSearch}
                                        onChange={({ target }) => setSkuSearch(target.value)}
                                    />
                                </div>
                                <div className='dark:text-black grid grid-cols-[600px_1fr_1fr] gap-[16px] bg-darkgrey rounded-[4px] p-[8px] mb-[-8px]'>
                                    <p className='text-[12px] text-offwhite uppercase font-bold leading-[1]'>Title</p>
                                    <p className='text-[12px] text-offwhite uppercase font-bold leading-[1]'>SKU</p>
                                    <p className='text-[12px] text-offwhite uppercase font-bold leading-[1]'>
                                        Supplier SKU
                                    </p>
                                </div>
                                <div className='min-h-[300px] overflow-auto'>
                                    {filteredProducts.map((p) => (
                                        <div className='dark:text-black grid grid-cols-[600px_1fr_1fr] items-center gap-[16px] p-[8px] bg-lightgrey rounded-[4px] my-[8px]'>
                                            <p>{p.title}</p>
                                            <p>{p.sku}</p>
                                            <input
                                                type='text'
                                                name={p.sku}
                                                value={skuMap[p.sku]}
                                                onChange={({ target }) =>
                                                    setSkuMap((prev) => ({ ...prev, [p.sku]: target.value }))
                                                }
                                            />
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                        {isNew ? (
                            <button
                                onClick={handleSubmit}
                                className='mx-auto mt-[16px] py-[4px] px-[16px] text-[24px] font-bold bg-blue dark:text-offwhite text-white shadow-cool uppercase font-bai rounded-[4px]'
                            >
                                Create
                            </button>
                        ) : (
                            <button
                                onClick={handleSubmit}
                                className='mx-auto mt-[16px] py-[4px] px-[16px] text-[24px] font-bold bg-blue dark:text-offwhite text-white shadow-cool uppercase font-bai rounded-[4px]'
                            >
                                Update
                            </button>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}
